import { graphql } from "gatsby"
import React from "react"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Seo from "../components/App/Seo"
import PageBanner from "../components/Common/PageBanner"
import StartProject from "../components/Common/StartProject"
import ServicesOne from "../components/Services/ServicesOne"

const Services = ({ data }) => {
  const { metaTags, strapiStartYourProject } = data
  const { metaImage } = metaTags
  const pageMeta = metaTags?.metas?.filter(item => {
    return item.page === "services"
  })[0]
  return (
    <Layout>
      <Seo
        title={pageMeta?.metaTitle}
        description={pageMeta?.metaDesc}
        keywords={pageMeta?.metaKeyword}
        metaImage={metaImage}
        url={"services"}
      />
      <Navbar />
      <PageBanner
        pageTitle="Services"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Services"
      />
      <ServicesOne />
      <StartProject strapiStartYourProject={strapiStartYourProject} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleHomeDetails {
    metaTags: strapiMetaTags {
      metaImage {
        url
      }
      metas {
        page
        metaTitle
        metaKeyword
        metaDesc
      }
    }
    strapiStartYourProject {
      id
      title
      shortText
      btnLink
      btnText
      images {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`
export default Services
