import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

import starIcon from "../../assets/images/star-icon.png"

const query = graphql`
  {
    strapiServices {
      title
      subTitle
      longDesc
      btnLink
      btnLinkText
      image {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
    allStrapiServiceSolution(sort: { fields: order, order: ASC }) {
      nodes {
        icon
        title
        homeDesc
        shortText
        slug
        iconImage {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`

const ServicesOne = () => {
  const data = useStaticQuery(query)

  const {
    allStrapiServiceSolution: { nodes: solutions },
    strapiServices: {
      title,
      subTitle,
      longDesc,
      btnLink,
      btnLinkText,
      image: {
        localFile: {
          childImageSharp: {
            original: { src: serviceImage },
          },
        },
      },
    },
  } = data

  return (
    <section className="solutions-area ptb-70">
      <div className="container">
        <div className="container-fluid pb-70">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <div className="content">
                  <span className="sub-title">
                    <img src={starIcon} alt="banner" />
                    {title}
                  </span>
                  <h2>{subTitle}</h2>
                  <ReactMarkdown
                    children={longDesc}
                    rehypePlugins={[rehypeRaw]}
                  />
                  <Link to={btnLink} className="default-btn">
                    <i className="flaticon-web"></i>
                    {btnLinkText}
                    <span></span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src={serviceImage} alt="feature" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {solutions.map((solution, idx) => {
            const {
              slug,
              title,
              homeDesc,
              iconImage: {
                localFile: {
                  childImageSharp: {
                    original: { src: iconImageSrc },
                  },
                },
              },
            } = solution
            return (
              <div className="col-lg-4 col-sm-6 mb-4" key={idx}>
                <div className="single-solutions-box">
                  <div className="icon">
                    {/* <i className={icon}></i> */}
                    <img src={iconImageSrc} alt="Service Icon" />
                  </div>
                  <h3>
                    <Link to={`/services/${slug}`}>{title}</Link>
                  </h3>
                  <p>{homeDesc}</p>
                  {/* <Link
                    to={`/services/${slug}`}
                    className="view-details-btn service-view-details-btn"
                  >
                    View Details
                  </Link> */}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ServicesOne
